// SASS Variables
$default-heading-color: #3e3e3c;
$default-subheading-color: #706e6b;
$default-label-font-size: 0.75rem;
$brand-primary-color: #1589ee;

html {
  height: 100%;
  background: white;

  #mount {
    .hidden {
      display: none;
    }

    #mobilepush-inbox-activity {
      display: flex;
      justify-content: center;
      align-items: center;

      #message-table {
        height: 100vh;
        width: 100%;
        overflow-y: auto;
        border: 1px solid #dddbda;
      }
    }
  }
}

@import "~@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.css";
